define('n2-scrip/routes/forgot-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    deactivate: function deactivate() {
      this.controller.set('linkSent', false);
      this.controller.set('email', null);
    }
  });
});