define('n2-scrip/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'moment', 'n2-scrip/utils/presence-validator', 'n2-scrip/utils/password-format-validator', 'n2-scrip/config/environment'], function (exports, _model, _attr, _relationships, _emberCpValidations, _moment, _presenceValidator, _passwordFormatValidator, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var termsOfServiceDate = _environment.default.termsOfServiceDate;


  var Validations = (0, _emberCpValidations.buildValidations)({
    userName: [(0, _presenceValidator.default)('Email'), (0, _emberCpValidations.validator)('format', { type: 'email' })],
    password: [(0, _presenceValidator.default)('Password', {
      disabled: Ember.computed.not('model.isNew')
    }), (0, _passwordFormatValidator.default)(), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password'
    })],
    passwordConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match',
      disabled: Ember.computed.empty('model.password')
    })
  });

  exports.default = _model.default.extend(Validations, {
    firstName: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    userName: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),

    guestDetail: (0, _relationships.belongsTo)('scrip-guest-detail', { async: false }),
    coordinatorDetail: (0, _relationships.belongsTo)('scrip-coordinator-detail', { async: false }),

    isGuest: Ember.computed('guestDetail', function () {
      return this.belongsTo('guestDetail').value() !== null;
    }),

    isCoordinator: Ember.computed('coordinatorDetail', function () {
      return this.belongsTo('coordinatorDetail').value() !== null;
    }),

    isScripUser: Ember.computed.or('isGuest', 'isCoordinator'),

    isBoth: Ember.computed.and('isGuest', 'isCoordinator'),

    acceptedTermsAt: Ember.computed('guestDetail.acceptedTermsAt', 'coordinatorDetail.acceptedTermsAt', function () {
      return this.isGuest && this.get('guestDetail.acceptedTermsAt') || this.isCoordinator && this.get('coordinatorDetail.acceptedTermsAt');
    }),

    hasAcceptedTerms: Ember.computed('acceptedTermsAt', function () {
      return this.acceptedTermsAt && (0, _moment.default)(termsOfServiceDate).isBefore(this.acceptedTermsAt);
    })
  });
});