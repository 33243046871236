define('n2-scrip/routes/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    activate: function activate() {
      Ember.run.scheduleOnce('afterRender', function () {
        document.querySelectorAll('input')[0].focus();
      });
    },
    beforeModel: function beforeModel(_ref) {
      var magicLink = _ref.queryParams.magicLink;

      if (magicLink) {
        return this.get('session').authenticate('authenticator:n2-jsonapi', [null, null, null, magicLink]);
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        email: null,
        password: null,
        errorMessage: null
      });
    },
    deactivate: function deactivate() {
      this.set('controller.coordinatorToken', null);
    }
  });
});