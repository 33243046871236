define('n2-scrip/services/sentry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    raven: Ember.inject.service(),

    captureException: function captureException(error) {
      if (this.get('raven.isRavenUsable')) {
        this.get('raven').captureException(error);
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  });
});