define('n2-scrip/helpers/array', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.array = array;
    function array(params) {
        return params;
    }
    exports.default = Ember.Helper.helper(array);
});