define('n2-scrip/components/scrip-account-details', ['exports', 'n2-scrip/utils/store-options'], function (exports, _storeOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    saveError: null,

    sentry: Ember.inject.service(),

    currentUser: Ember.inject.service(),

    flashMessage: Ember.inject.service(),

    newsletterAd: false,
    newsletterFTM: false,
    newsletterSonoma: false,
    newsletterForkLift: false,

    groupedLocations: Ember.computed('locations', function () {
      return (0, _storeOptions.mapDataModels)(this.locations);
    }),

    isPasswordRequired: Ember.computed.readOnly('guestDetails.isNew'),

    isPasswordConfirmationRequired: Ember.computed('isPasswordRequired', 'user.password', function () {
      return this.isPasswordRequired || Ember.isPresent(this.get('user.password'));
    }),

    canSave: Ember.computed.and('guestDetails.validations.isValid', 'user.validations.isValid'),

    actions: {
      save: function save(ev) {
        ev.preventDefault();
        this.onsubmit({
          newsletterAd: this.newsletterAd,
          newsletterFTM: this.newsletterFTM,
          newsletterSonoma: this.newsletterSonoma,
          newsletterForkLift: this.newsletterForkLift
        });
      },
      updatePhone: function updatePhone(number) {
        this.set('guestDetails.phone', number.replace(/\W/g, ''));
      }
    }
  });
});