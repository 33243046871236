define('n2-scrip/validators/scrip-card', ['exports', 'ember-cp-validations/validators/base', 'n2-scrip/utils/upc-check-digit'], function (exports, _base, _upcCheckDigit) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.errorMessages = undefined;
    exports.validateStart = validateStart;

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var errorMessages = exports.errorMessages = {
        invalidStart: 'The card number does not appear to be valid. Card number should start with "42000" or "43000"',
        invalid12Length: 'Card numbers starting with "420001" must be 12 digits long.',
        invalidCheckDigit: 'The card number does not appear to be valid.',
        invalid11Length: function invalid11Length(firstSix) {
            return 'Cards starting with "' + firstSix + '" must be 11 digits long.';
        },
        invalid11Or12Length: function invalid11Or12Length(firstSix) {
            return 'Cards starting with "' + firstSix + '" must be 11 or 12 digits long.';
        }
    };
    function validateStart(value) {
        var start = value.substring(0, 6);
        return start === '420000' || start === '430000' || start === '420001';
    }

    var UpcCheckDigit = function (_BaseValidator) {
        _inherits(UpcCheckDigit, _BaseValidator);

        function UpcCheckDigit() {
            _classCallCheck(this, UpcCheckDigit);

            return _possibleConstructorReturn(this, (UpcCheckDigit.__proto__ || Object.getPrototypeOf(UpcCheckDigit)).apply(this, arguments));
        }

        _createClass(UpcCheckDigit, [{
            key: 'validate',
            value: function validate(value, options) {
                var firstSix = value.substring(0, 6);
                if (!validateStart(value)) {
                    return errorMessages.invalidStart;
                }
                if (firstSix === '420001') {
                    if (value.length !== 12) {
                        return errorMessages.invalid12Length;
                    }
                    return (0, _upcCheckDigit.default)(value).toString() == value[11].toString() ? true : errorMessages.invalidCheckDigit;
                }
                if (firstSix === '420000') {
                    if (value.length === 11) {
                        return true;
                    } else if (value.length === 12) {
                        return (0, _upcCheckDigit.default)(value).toString() == value[11].toString() ? true : errorMessages.invalidCheckDigit;
                    }
                    return errorMessages.invalid11Or12Length(firstSix);
                }
                return value.length === 11 ? true : errorMessages.invalid11Length(firstSix);
            }
        }]);

        return UpcCheckDigit;
    }(_base.default);

    exports.default = UpcCheckDigit;

    ;
});