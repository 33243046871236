define('n2-scrip/models/scrip-guest-detail', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'n2-scrip/utils/presence-validator'], function (exports, _model, _attr, _relationships, _emberCpValidations, _presenceValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.GuestDetailValidations = undefined;
  var GuestDetailValidations = exports.GuestDetailValidations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _presenceValidator.default)('First name'),
    lastName: (0, _presenceValidator.default)('Last name'),
    zipCode: (0, _presenceValidator.default)('Zip code'),
    location: (0, _presenceValidator.default)('Location'),
    phone: (0, _emberCpValidations.validator)('format', {
      regex: /^\d{10}$/,
      allowBlank: true,
      message: 'Phone number must be 10 digits'
    })
  });

  exports.default = _model.default.extend(GuestDetailValidations, {
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zipCode: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    user: (0, _relationships.belongsTo)('user', { async: false }),
    location: (0, _relationships.belongsTo)('legacy-scrip-store'),
    acceptedTermsAt: (0, _attr.default)('date'),
    scripGuestOrganizations: (0, _relationships.hasMany)('scrip-guest-organization'),

    hasAcceptedTerms: Ember.computed.notEmpty('acceptedTermsAt')
  });
});