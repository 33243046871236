define('n2-scrip/authenticators/n2-jsonapi', ['exports', 'ember-simple-auth/authenticators/base', 'n2-scrip/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _base.default.extend({
    ajax: Ember.inject.service('ajax'),

    store: Ember.inject.service('store'),

    currentUser: Ember.inject.service('current-user'),

    raven: Ember.inject.service(),

    restore: function restore(data) {
      return this.get('currentUser').load().then(function () {
        return Ember.RSVP.Promise.resolve(data);
      });
    },
    authenticate: function authenticate(_ref) {
      var _this = this;

      var _ref2 = _slicedToArray(_ref, 4),
          email = _ref2[0],
          password = _ref2[1],
          coordinatortoken = _ref2[2],
          magicLink = _ref2[3];

      var loginData = {
        username: email,
        password: password,
        coordinatortoken: coordinatortoken,
        oneTimeToken: magicLink,
        tenantshortcode: _environment.default.APP.tenantShortCode
      };

      return this.get('ajax').post('login', {
        data: loginData,
        dataType: 'text',
        xhrFields: {
          withCredentials: true
        }
      }).then(function (data) {
        return _this.get('currentUser').load().then(function () {
          return Ember.RSVP.Promise.resolve(data);
        });
      });
    },
    invalidate: function invalidate() {
      if (this.get('raven.isRavenUsable')) {
        this.get('raven').callRaven('setUserContext');
      }
      return Ember.RSVP.Promise.resolve();
    }
  });
});