define('n2-scrip/utils/upc-check-digit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = upcCheckDigit;
    exports.appendCheckDigit = appendCheckDigit;
    function getValidDigits(str) {
        return str.substring(0, 11);
    }
    function upcCheckDigit(str) {
        var digits = getValidDigits(str).split('').map(function (a) {
            return parseInt(a);
        });
        var oddSum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8] + digits[10];
        var evenSum = digits[1] + digits[3] + digits[5] + digits[7] + digits[9];
        var check1 = oddSum * 3;
        var check2 = check1 + evenSum;
        var check3 = check2 % 10;
        return check3 === 0 ? 0 : 10 - check3;
    }
    function appendCheckDigit(str) {
        return '' + getValidDigits(str) + upcCheckDigit(str);
    }
});