define('n2-scrip/routes/signup/org', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      var locations = this.store.query('legacy-scrip-store', { sort: 'name' });
      var preregistration = this.store.createRecord('scrip-organization-preregistration', { tenantShortCode: 'nugget' });
      return Ember.RSVP.hash({
        preregistration: preregistration,
        locations: locations
      });
    },
    resetController: function resetController(controller) {
      controller.set('step', 0);
    }
  });
});