define("n2-scrip/utils/store-options", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapDataModels = mapDataModels;
  var storeOptions = [{
    groupName: 'Greater Sacramento Area',
    options: [{ "id": "12", "name": "Nugget Markets - Davis, Covell" }, { "id": "2", "name": "Nugget Markets - Davis, Mace" }, { "id": "9", "name": "Nugget Markets - El Dorado Hills" }, { "id": "8", "name": "Nugget Markets - Elk Grove" }, { "id": "5", "name": "Nugget Markets - Sacramento, Florin" }, { "id": "7", "name": "Nugget Markets - Roseville" }, { "id": "11", "name": "Nugget Markets - Vacaville" }, { "id": "6", "name": "Nugget Markets West Sacramento" }, { "id": "1", "name": "Nugget Markets Woodland" }, { "id": "21", "name": "Fork Lift by Nugget Markets - Cameron Park" }, { "id": "30", "name": "Food 4 Less - Woodland" }]
  }, {
    groupName: 'Marin County',
    options: [{ "id": "15", "name": "Nugget Markets - Corte Madera" }, { "id": "16", "name": "Nugget Markets - Novato" }, { "id": "14", "name": "Nugget Markets - Tiburon" }]
  }, {
    groupName: 'Sonoma Valley',
    options: [{ "id": "17", "name": "Sonoma Market" }]
  }];

  function mapDataModels(models) {
    return storeOptions.map(function (_ref) {
      var groupName = _ref.groupName,
          options = _ref.options;

      return {
        groupName: groupName,
        options: options.map(function (store) {
          var model = models.find(function (m) {
            return m.id == store.id;
          });
          if (!model) {
            return undefined;
          }

          model.set('displayName', store.name);

          return model;
        }).filter(function (a) {
          return a;
        })
      };
    });
  }

  exports.default = storeOptions.map(function (a) {
    return a;
  });
});