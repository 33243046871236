define('n2-scrip/services/flash-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    flashMessages: Ember.inject.service(),

    success: function success(message) {
      this.flashMessages.clearMessages();
      this.flashMessages.success(message);
    }
  });
});