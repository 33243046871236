define('n2-scrip/models/legacy-scrip-store', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    freshToMarketId: (0, _attr.default)('string'),
    sonomaAdId: (0, _attr.default)('string'),
    forkLiftAdId: (0, _attr.default)('string'),
    adId: (0, _attr.default)('string')
  });
});