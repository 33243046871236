define('n2-scrip/components/date-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    actions: {
      select: function select(close, value) {
        this.onchange(value.moment.format('YYYY-MM-DD'));
        close();
      }
    }
  });
});