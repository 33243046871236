define('n2-scrip/models/scrip-coordinator-organization', ['exports', 'ember-data/model', 'ember-data/relationships'], function (exports, _model, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    organization: (0, _relationships.belongsTo)('scrip-organization', { async: false }),
    coordinatorDetail: (0, _relationships.belongsTo)('scrip-coordinator-detail')
  });
});