define('n2-scrip/helpers/format-phone-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPhoneNumber = formatPhoneNumber;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function formatPhoneNumber(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 1),
        phone = _ref2[0];

    if (Ember.isEmpty(phone)) {
      return '';
    }
    var areaCode = phone.slice(0, 3);
    var exchange = phone.slice(3, 6);
    var rest = phone.slice(6);

    return [areaCode, exchange, rest].filter(function (n) {
      return !Ember.isEmpty(n);
    }).join('-');
  }

  exports.default = Ember.Helper.helper(formatPhoneNumber);
});