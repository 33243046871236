define('n2-scrip/controllers/user/cards/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    deleteError: null,

    sentry: Ember.inject.service(),

    flashMessage: Ember.inject.service(),

    actions: {
      deleteCard: function deleteCard(card) {
        var _this = this;

        this.set('deleteError', null);

        card.set('enabled', false);
        card.save().then(function () {
          card.unloadRecord();
          _this.get('flashMessage').success('Card ' + card.cardNumber + ' has been removed.');
        }).catch(function (e) {
          card.set('enabled', true);
          _this.get('sentry').captureException(e);
          _this.set('deleteError', 'An error occurred while deleting your card. Please try again.');
        });
      }
    }
  });
});