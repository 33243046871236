define('n2-scrip/controllers/signup/org', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['step'],

    welcomeStep: 0,

    termsStep: 1,

    signupStep: 2,

    thankYouStep: 3,

    step: 0,

    actions: {
      goToNextStep: function goToNextStep(stepNumber) {
        this.set('step', stepNumber + 1);
      },
      goToPreviousStep: function goToPreviousStep(stepNumber) {
        this.set('step', stepNumber - 1);
      }
    }
  });
});