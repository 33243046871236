define('n2-scrip/utils/presence-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = presenceValidator;
  function presenceValidator(description) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return (0, _emberCpValidations.validator)('presence', Ember.merge({
      presence: true,
      ignoreBlank: true,
      message: '{description} is required',
      description: description
    }, options));
  }
});