define('n2-scrip/services/new-guest', ['exports', 'n2-scrip/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    guestRegistration: null,
    card: null,

    init: function init() {
      this._super.apply(this, arguments);

      var registration = this.store.createRecord('scrip-guest-registration');
      registration.set('tenantShortCode', _environment.default.APP.tenantShortCode);

      this.set('guestRegistration', registration);
    },
    getCard: function getCard() {
      var scripGuestDetail = this.get('currentUser.user.guestDetail');
      if (this.card) {
        return this.card;
      }

      var card = this.store.createRecord('scrip-card', {
        scripGuestDetail: scripGuestDetail,
        enabled: true
      });

      return this.set('card', card);
    }
  });
});