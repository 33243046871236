define('n2-scrip/models/scrip-card', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'n2-scrip/utils/presence-validator', 'n2-scrip/utils/upc-check-digit'], function (exports, _model, _attr, _relationships, _emberCpValidations, _presenceValidator, _upcCheckDigit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    fullCardNumber: [(0, _emberCpValidations.validator)('scrip-card'), (0, _emberCpValidations.validator)('confirmation', {
      on: 'fullCardNumber'
    })],
    cardNumberConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'fullCardNumber',
      message: 'Card #s do not match',
      disabled: Ember.computed.not('model.validations.attrs.fullCardNumber.isValid')
    }),
    name: (0, _presenceValidator.default)('Name')
  });

  exports.default = _model.default.extend(Validations, {
    fullCardNumber: '',
    cardNumberConfirmation: '',

    cardNumber: (0, _attr.default)('string'),
    enabled: (0, _attr.default)('boolean'),
    name: (0, _attr.default)('string'),
    scripGuestDetail: (0, _relationships.belongsTo)('scrip-guest-detail'),

    displayNumber: Ember.computed('cardNumber', function () {
      var checkDigit = (0, _upcCheckDigit.default)(this.cardNumber);

      return '' + this.cardNumber + checkDigit;
    }),

    setCardNumber: function setCardNumber() {
      this.set('cardNumber', this.fullCardNumber.substring(0, 11));
    },
    save: function save() {
      if (this.fullCardNumber) {
        this.setCardNumber();
      }

      return this._super.apply(this, arguments);
    }
  });
});