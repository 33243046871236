define('n2-scrip/models/scrip-guest-registration', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'n2-scrip/utils/presence-validator', 'n2-scrip/utils/password-format-validator'], function (exports, _model, _attr, _relationships, _emberCpValidations, _presenceValidator, _passwordFormatValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.GuestRegistrationValidations = undefined;
  var GuestRegistrationValidations = exports.GuestRegistrationValidations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _presenceValidator.default)('First name'),
    lastName: (0, _presenceValidator.default)('Last name'),
    userName: [(0, _presenceValidator.default)('Email'), (0, _emberCpValidations.validator)('format', { type: 'email' })],
    zipCode: [(0, _presenceValidator.default)('Zip code'), (0, _emberCpValidations.validator)('length', {
      is: 5,
      message: 'Zip code must be 5 digits.'
    })],
    phone: [(0, _presenceValidator.default)('Phone'), (0, _emberCpValidations.validator)('length', {
      is: 10,
      message: 'Phone number must be 10 digits.'
    })],
    location: (0, _presenceValidator.default)('Location'),
    password: [(0, _presenceValidator.default)('Password'), (0, _passwordFormatValidator.default)(), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password'
    })],
    passwordConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match',
      disabled: Ember.computed.empty('model.password')
    })
  });

  exports.default = _model.default.extend(GuestRegistrationValidations, {
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    userName: Ember.computed.alias('email'),
    zipCode: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    tenantShortCode: (0, _attr.default)('string'),
    passwordConfirmation: (0, _attr.default)('string'),
    location: null,
    locationId: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)('user', { async: false }),
    guestDetail: (0, _relationships.belongsTo)('scrip-guest-detail', { async: false })
  });
});