define('n2-scrip/components/progress-tracker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    totalSteps: null,

    currentStep: null,

    steps: Ember.computed('totalSteps', function () {
      var steps = [];
      for (var i = 1; i <= this.totalSteps; i++) {
        steps.push(i);
      }
      return steps;
    })
  });
});