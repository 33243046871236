define('n2-scrip/controllers/user/contributions', ['exports', 'n2-scrip/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    reportMinDate: (0, _moment.default)(_environment.default.reports.minDate),
    queryParams: {
      startDate: 'start',
      endDate: 'end'
    },

    actions: {
      setDate: function setDate(mut, close, selection) {
        mut(selection.moment.format('YYYY-MM-DD'));
        close();
      }
    }
  });
});