define('n2-scrip/routes/organization/detail', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _paramsFor = this.paramsFor('organization.detail'),
          id = _paramsFor.id;

      if (!this.currentUser.organizations.find(function (a) {
        return a.id == id;
      })) {
        return this.transitionTo('dashboard');
      }
    },
    model: function model(params) {
      return this.store.peekRecord('scrip-organization', params.id);
    }
  });
});