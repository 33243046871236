define('n2-scrip/models/scrip-organization', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-cp-validations', 'n2-scrip/utils/presence-validator'], function (exports, _model, _attr, _emberCpValidations, _presenceValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _presenceValidator.default)('Organization name'),
    addressLine1: (0, _presenceValidator.default)('Mailing address'),
    city: (0, _presenceValidator.default)('City'),
    state: (0, _emberCpValidations.validator)('format', {
      regex: /^[a-zA-Z]{2}$/,
      message: 'State must be 2 letters'
    }),
    zipCode: (0, _emberCpValidations.validator)('format', {
      regex: /^\d{5}$/,
      message: 'Zip code must be 5 digits'
    }),
    phone: (0, _emberCpValidations.validator)('format', {
      regex: /^\d{10}$/,
      allowBlank: true,
      message: 'Phone number must be 10 digits'
    }),
    bankRoutingNumber: (0, _emberCpValidations.validator)('format', {
      regex: /^\d{9}$/,
      message: 'Routing number must be 9 digits',
      disabled: Ember.computed.readOnly('model.achOptOut')
    }),
    bankAccountNumber: [(0, _presenceValidator.default)('Account number', {
      disabled: Ember.computed.readOnly('model.achOptOut')
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      integer: true,
      gt: 0,
      description: 'Account number',
      disabled: Ember.computed.readOnly('model.achOptOut')
    })],
    payeeName: (0, _presenceValidator.default)('Payee name', {
      disabled: Ember.computed.not('model.achOptOut')
    })
  });

  exports.default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    addressLine1: (0, _attr.default)('string'),
    addressLine2: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zipCode: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    webSite: (0, _attr.default)('string'),
    w9S3Key: (0, _attr.default)('string'),
    shortName: (0, _attr.default)('string'),
    bankAccountNumber: (0, _attr.default)('string'),
    bankRoutingNumber: (0, _attr.default)('string'),
    achOptOut: (0, _attr.default)('boolean'),
    payeeName: (0, _attr.default)('string')
  });
});