define('n2-scrip/components/validation-error', ['exports', 'n2-scrip/helpers/errors-for'], function (exports, _errorsFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hasFocusedOut: true,
    emberDataProperty: Ember.computed.oneWay('property'),

    cpValidationError: Ember.computed('context', 'property', 'context.validations.isValid', function () {
      return this.get('context.validations.attrs.' + this.property + '.isInvalid');
    }),

    emberDataError: Ember.computed('emberDataErrorsList', function () {
      return (this.get('emberDataErrorsList') || []).length > 0;
    }),

    emberDataErrorsList: Ember.computed(('context.errors.@each', 'context.errors', 'emberDataProperty', function () {
      return this.get('context.errors.' + this.emberDataProperty) || [];
    })),

    responseError: Ember.computed('responseErrorsList', function () {
      return this.get('responseErrorsList').length > 0;
    }),

    responseErrorsList: Ember.computed('errors', 'emberDataProperty', function () {
      return (0, _errorsFor.errorsFor)([this.errors || [], this.emberDataProperty]);
    }),

    showError: Ember.computed.or('cpValidationError', 'responseError', 'emberDataError'),

    errorMessage: Ember.computed('property', 'responseErrorsList', 'emberDataErrorsList', 'context.isValid', 'showError', function () {
      if (this.cpValidationError) {
        return this.get('context.validations.attrs.' + this.property + '.message');
      } else if (this.responseError) {
        return this.get('responseErrorsList').map(function (a) {
          return Ember.get(a, 'detail');
        }).join(', ');
      } else if (this.emberDataError) {
        return (this.get('emberDataErrorsList') || []).map(function (a) {
          return Ember.get(a, 'message');
        }).join(', ');
      }
    })
  });
});