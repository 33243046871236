define('n2-scrip/routes/user/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var guestDetail = this.modelFor('user');
      return guestDetail.get('location');
    },
    model: function model() {
      var guestDetails = this.modelFor('user');
      var locations = this.store.query('legacy-scrip-store', {
        sort: 'name'
      });

      return Ember.RSVP.hash({
        guestDetails: guestDetails,
        locations: locations
      });
    }
  });
});