define('n2-scrip/controllers/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    actions: {
      logout: function logout() {
        this.session.invalidate();
      }
    }
  });
});