define('n2-scrip/routes/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sentry: Ember.inject.service(),

    setupController: function setupController(controller, error) {
      this.get('sentry').captureException(error);

      var isServerError = true;

      if (!Ember.isEmpty(error.errors) && error.errors[0].status && error.errors[0].status.startsWith('4')) {
        isServerError = false;
      }
      controller.set('isServerError', isServerError);
      this._super(controller, error);
    }
  });
});