define("n2-scrip/models/scrip-organization-donor-report", ["exports", "ember-data", "ember-data/relationships"], function (exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    donorFirstName: attr(),
    donorLastName: attr(),
    contributionAmount: attr(),
    qualifiedAmount: attr(),

    totalsReport: (0, _relationships.belongsTo)('scrip-organization-donor-total-report')
  });
});