define('n2-scrip/controllers/organization/detail/statements/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      statementId: 'statement'
    },

    actions: {
      changeStatement: function changeStatement(statement) {
        this.set('statementId', statement.id);
      }
    }
  });
});