define('n2-scrip/services/terms-update', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    accept: function accept(user) {
      var promises = [];

      if (user.get('guestDetail')) {
        user.set('guestDetail.acceptedTermsAt', new Date());
        promises.push(user.get('guestDetail').save());
      }
      if (user.get('coordinatorDetail')) {
        user.set('coordinatorDetail.acceptedTermsAt', new Date());
        promises.push(user.get('coordinatorDetail').save());
      }
      return Ember.RSVP.all(promises);
    }
  });
});