define('n2-scrip/utils/w9-upload', ['exports', 'n2-scrip/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = w9Upload;
  function w9Upload(s3key, file) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var formData = new FormData();

      formData.append('key', s3key);
      formData.append('file', file);

      var request = new XMLHttpRequest();

      // TODO: Re-enable ACL once S3 permissions get fixed
      // request.setRequestHeader('x-amz-acl', 'authenticated-read');

      request.addEventListener('load', function (e) {
        Ember.run(function () {
          if (request.status >= 200 && request.status < 400) {
            resolve(e);
          } else {
            reject('W-9 upload did not complete successfully. Status: ' + request.statusText + ', XML response: ' + request.responseXML);
          }
        });
      });

      request.addEventListener('error', function () {
        Ember.run(function () {
          reject('W-9 upload did not complete due to an error.');
        });
      });

      request.open('POST', '//' + _environment.default.APP.w9UploadBucketName + '.s3.amazonaws.com/');
      request.send(formData);
    });
  }
});