define('n2-scrip/adapters/application', ['exports', 'ember-data', 'n2-scrip/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.APP.host,
    namespace: _environment.default.APP.namespace,

    ajax: function ajax(url, method) {
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      hash.xhrFields = { withCredentials: true };

      return this._super(url, method, hash);
    }
  });
});