define('n2-scrip/routes/user/beneficiaries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var scripGuestDetail = this.modelFor('user');
      var organizations = this.store.query('scrip-organization', {
        sort: 'name',
        filter: {
          enabled: true
        }
      });
      var scripGuestOrganizations = this.store.query('scrip-guest-organization', {
        include: 'scrip-organization'
      });

      return Ember.RSVP.hash({
        scripGuestDetail: scripGuestDetail,
        organizations: organizations,
        scripGuestOrganizations: scripGuestOrganizations
      });
    }
  });
});