define('n2-scrip/helpers/ls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function isNumber(num) {
    return typeof num === 'number' || !isNaN(parseFloat(num));
  }

  var localeString = function localeString(_ref) {
    var _ref3 = _slicedToArray(_ref, 1),
        _ref3$ = _ref3[0],
        value = _ref3$ === undefined ? 0 : _ref3$;

    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref2$decimals = _ref2.decimals,
        decimals = _ref2$decimals === undefined ? false : _ref2$decimals,
        _ref2$truncate = _ref2.truncate,
        truncate = _ref2$truncate === undefined ? false : _ref2$truncate;

    value = isNumber(value) ? parseFloat(value) : 0;

    var output = decimals ? numberWithCommas(value.toFixed(2)) : numberWithCommas(value.toFixed(0));

    output = truncate ? output.replace('.00', '') : output;

    return isNaN(value) ? '-' : output;
  };

  exports.localeString = localeString;
  exports.default = Ember.Helper.helper(localeString);
});