define('n2-scrip/router', ['exports', 'n2-scrip/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('signup', function () {
      this.route('user');
      this.route('org');
    });
    this.route('user', function () {
      this.route('account');
      this.route('cards', function () {
        this.route('new');
      });
      this.route('beneficiaries');
      this.route('contributions');
    });
    this.route('organization', function () {
      this.route('detail', { path: '/:id' }, function () {
        this.route('account');
        this.route('statements', function () {});
        this.route('donors');
        this.route('coordinator');
      });
    });
    this.route('login');
    this.route('forgot-password');
    this.route('change-password');
    this.route('dashboard');
    this.route('terms');
    this.route('invitation', { path: '/invitation/:token' });

    this.route('not-found', { path: '/*path' });
  });

  exports.default = Router;
});