define('n2-scrip/routes/organization/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service('current-user'),
    model: function model() {
      return this.currentUser.get('organizations');
    },
    afterModel: function afterModel(model) {
      if (!this.currentUser.hasMultipleOrganizations) {
        var organization = model.get('firstObject');

        this.transitionTo('organization.detail', organization.id);
      }
    }
  });
});