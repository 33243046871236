define('n2-scrip/routes/dashboard', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),

    beforeModel: function beforeModel() {
      this._super();
      var user = this.currentUser.user;

      if (!user || user.isBoth) {
        return;
      } else if (user.isGuest) {
        this.transitionTo('user');
      } else {
        return this.transitionTo('organization');
      }
    }
  });
});