define('n2-scrip/adapters/scrip-guest-registration', ['exports', 'n2-scrip/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      var url = this._super.apply(this, arguments);

      if (requestType === 'createRecord') {
        url = url + '?include=user,guest-detail';
      }

      return url;
    }
  });
});