define('n2-scrip/services/ajax', ['exports', 'ember-ajax/services/ajax', 'n2-scrip/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    host: _environment.default.APP.host,
    namespace: _environment.default.APP.namespace,

    options: function options() {
      var xhrFieldsOptions = {
        xhrFields: {
          withCredentials: true
        }
      };

      var options = this._super.apply(this, arguments);

      return Object.assign({}, xhrFieldsOptions, options);
    }
  });
});