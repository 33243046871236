define('n2-scrip/utils/password-format-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = passwordFormatValidator;
  function passwordFormatValidator() {
    return (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
      message: 'Password must be 8 or more characters, and must include at least one uppercase letter and one number',
      disabled: Ember.computed.empty('model.password')
    });
  }
});