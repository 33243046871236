define('n2-scrip/utils/is-validation-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isValidationError;
  function isValidationError() {
    var err = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};


    if (Array.isArray(err)) {
      return isValidationError(Ember.get(err, '0'));
    } else if (Array.isArray(Ember.get(err, 'errors'))) {
      return isValidationError(Ember.get(err, 'errors.0'));
    }

    return err.status === 422 || err.status === '422';
  }
});