define('n2-scrip/controllers/user/cards/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    backRoute: 'user.cards',

    actions: {
      save: function save() {
        this.transitionToRoute('user.cards');
      }
    }
  });
});