define('n2-scrip/models/scrip-coordinator-detail', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'n2-scrip/utils/presence-validator'], function (exports, _model, _attr, _relationships, _emberCpValidations, _presenceValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _presenceValidator.default)('First name'),
    lastName: (0, _presenceValidator.default)('Last name'),
    phone: (0, _emberCpValidations.validator)('format', {
      regex: /^\d{10}$/,
      message: 'Coordinator phone number must be 10 digits'
    })
  });

  exports.default = _model.default.extend(Validations, {
    user: (0, _relationships.belongsTo)('user', { async: false }),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    acceptedTermsAt: (0, _attr.default)('date'),
    coordinatorOrganizations: (0, _relationships.hasMany)('scrip-coordinator-organization', { async: false }),

    hasAcceptedTerms: Ember.computed.notEmpty('acceptedTermsAt')
  });
});