define('n2-scrip/routes/signup/user', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        locations: this.store.query('legacy-scrip-store', {
          sort: 'name'
        })
      });
    }
  });
});