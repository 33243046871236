define("n2-scrip/models/scrip-organization-donor-total-report", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    contributionAmount: attr(),
    qualifiedAmount: attr()
  });
});