define('n2-scrip/routes/not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sentry: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var attemptedUrl = transition.intent.url;
      var errorMessage = 'User attempted to visit nonexistent url \'' + attemptedUrl + '\'';
      this.get('sentry').captureException(new Error(errorMessage));
    }
  });
});