define('n2-scrip/routes/change-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),

    activate: function activate() {
      if (this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }
    }
  });
});