define('n2-scrip/controllers/user/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('currentUser'),

    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});