define('n2-scrip/models/scrip-organization-preregistration', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-cp-validations', 'n2-scrip/utils/presence-validator'], function (exports, _model, _attr, _emberCpValidations, _presenceValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.OrganizationTypes = undefined;
  var OrganizationTypes = exports.OrganizationTypes = ['Nonprofit 501(c)(3)', 'Educational Institution', 'Organization Sanctioned by an Educational Institution'];

  var Validations = (0, _emberCpValidations.buildValidations)({
    organizationName: (0, _presenceValidator.default)('Name'),
    streetAddress: (0, _presenceValidator.default)('Mailing address'),
    city: (0, _presenceValidator.default)('City'),
    state: (0, _emberCpValidations.validator)('format', {
      regex: /^[a-zA-Z]{2}$/,
      message: 'State must be 2 letters'
    }),
    zipCode: (0, _emberCpValidations.validator)('format', {
      regex: /^\d{5}$/,
      message: 'Zip code must be 5 digits'
    }),
    coordinatorFirstName: (0, _presenceValidator.default)('Coordinator first name'),
    coordinatorLastName: (0, _presenceValidator.default)('Coordinator last name'),
    coordinatorPhone: [(0, _presenceValidator.default)('Coordinator phone number'), (0, _emberCpValidations.validator)('format', {
      regex: /^\d{10}$/,
      allowBlank: true,
      message: 'Coordinator phone number must be 10 digits'
    })],
    coordinatorEmail: [(0, _presenceValidator.default)('Coordinator email'), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowNonTld: true
    })],
    organizationType: (0, _presenceValidator.default)('Organization type'),
    location: (0, _presenceValidator.default)('Location'),
    educationalInstitutionName: (0, _presenceValidator.default)('Educational Institution Name', {
      disabled: Ember.computed.not('model.isSanctionedByEducationalInstitution')
    }),
    bankRoutingNumber: (0, _emberCpValidations.validator)('format', {
      regex: /^\d{9}$/,
      message: 'Routing number must be 9 digits',
      disabled: Ember.computed.readOnly('model.achOptOut')
    }),
    bankAccountNumber: [(0, _presenceValidator.default)('Account number', {
      disabled: Ember.computed.readOnly('model.achOptOut')
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      integer: true,
      gt: 0,
      description: 'Account number',
      disabled: Ember.computed.readOnly('model.achOptOut')
    })],
    payeeName: (0, _presenceValidator.default)('Payee name', {
      disabled: Ember.computed.not('model.achOptOut')
    }),
    numberOfCards: [(0, _presenceValidator.default)('Number of cards'), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0,
      description: 'Number of cards'
    })]
  });

  exports.default = _model.default.extend(Validations, {
    organizationName: (0, _attr.default)('string'),
    streetAddress: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zipCode: (0, _attr.default)('string'),
    website: (0, _attr.default)('string'),
    coordinatorFirstName: (0, _attr.default)('string'),
    coordinatorLastName: (0, _attr.default)('string'),
    coordinatorPhone: (0, _attr.default)('string'),
    coordinatorEmail: (0, _attr.default)('string'),
    organizationType: (0, _attr.default)('string'),
    educationalInstitutionName: (0, _attr.default)('string'),
    bankRoutingNumber: (0, _attr.default)('string'),
    bankAccountNumber: (0, _attr.default)('string'),
    achOptOut: (0, _attr.default)('boolean'),
    payeeName: (0, _attr.default)('string'),
    numberOfCards: (0, _attr.default)('number'),
    tenantShortCode: (0, _attr.default)('string'),
    w9S3Key: (0, _attr.default)('string'),
    acceptedTermsAt: (0, _attr.default)('date'),
    locationId: (0, _attr.default)('string'),

    isSanctionedByEducationalInstitution: Ember.computed.equal('organizationType', 'Organization Sanctioned by an Educational Institution')
  });
});