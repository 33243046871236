define('n2-scrip/services/forgot-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    sendResetLink: function sendResetLink(username) {
      var data = {
        username: username
      };
      return this.get('ajax').request('/user/password/forgot', {
        dataType: 'text',
        data: data
      });
    },
    performReset: function performReset(username, token, password) {
      var data = {
        username: username, token: token, password: password
      };
      return this.get('ajax').patch('/user/password/forgot', {
        dataType: 'text',
        data: data
      });
    }
  });
});