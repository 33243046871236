define('n2-scrip/controllers/signup/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var steps = {
    welcomeStep: 0,
    termsStep: 1,
    signupStep: 2,
    cardsStep: 3,
    beneficiariesStep: 4,
    thankYouStep: 5
  };

  var totalSteps = Object.keys(steps).length - 1;

  exports.default = Ember.Controller.extend({
    queryParams: ['step'],
    step: 0,
    steps: steps,
    totalSteps: totalSteps,

    features: Ember.inject.service(),
    newGuest: Ember.inject.service(),

    actions: {
      goToStep: function goToStep(stepNumber) {
        this.set('step', stepNumber);
      },
      goToNextStep: function goToNextStep(stepNumber) {
        this.set('step', stepNumber + 1);
      },
      goToPreviousStep: function goToPreviousStep(stepNumber) {
        this.set('step', stepNumber - 1);
      }
    }
  });
});