define('n2-scrip/authenticators/registration', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    ajax: Ember.inject.service(),

    currentUser: Ember.inject.service('current-user'),

    raven: Ember.inject.service(),

    restore: function restore(data) {
      return this.currentUser.load().then(function () {
        return Ember.RSVP.Promise.resolve(data);
      });
    },
    authenticate: function authenticate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.currentUser.load().then(function () {
        return Ember.RSVP.Promise.resolve(data);
      });
    },
    invalidate: function invalidate() {
      if (this.raven.isRavenUsable) {
        this.raven.callRaven('setUserContext');
      }
      return Ember.RSVP.Promise.resolve();
    }
  });
});