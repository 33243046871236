define('n2-scrip/components/validated-data-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    hasFocusedOut: false,

    testSelector: Ember.computed('property', function () {
      return Ember.String.dasherize(this.property);
    }),

    emberDataProperty: Ember.computed.oneWay('property')
  });
});