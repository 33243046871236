define('n2-scrip/components/scrip-card-signup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    newGuest: Ember.inject.service(),

    init: function init() {
      this._super();
      this.newGuest.getCard();
    }
  });
});